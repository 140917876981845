@import '../css/shared';

.SliderProductPage {

  .gallery-thumbnail-container {
   
    .swiper {
      .swiper-slide {
        width: auto;
        position: relative;
        overflow: hidden;
        border: 1px solid transparent;
        transition: all 0.5s;

        &:hover {
          border-color: $primary;
          cursor: pointer;
          
          &:before {border: 0px solid #FFFFFF;}
        }

        img {position: relative;}

      }

      .swiper-slide-thumb-active {border-color: $primary;}
      
    }

  }
  
  @include media-breakpoint-up(lg) {
      .gallery-slide-container {
        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }
      }
  }

}

