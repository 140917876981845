@import '../css/shared';

.swiper {
  &.auto-width {
    .swiper-slide {
      width: auto;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 40px;
    height: 40px;
    overflow: hidden;
  }

  .swiper-button-prev:after,
  .swiper-button-next:after {
    font-family: 'Icomoon';
    color: $black;
  }

  .swiper-button-prev:after {content: use-icon('e90e');}
  .swiper-button-next:after {content: use-icon('e90f');}
 
  .swiper-pagination .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    margin: 0 5px;

    @include media-breakpoint-down(md) {
      width: 10px;
      height: 10px;
      margin: 0 5px;
    }
  }

  .swiper-pagination {
    .swiper-pagination-bullet {
      background-color: $green-light;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background-color: $green;
    }
  }

  .slide-image {
    a,
    picture {
      display: block;
    }

    img {
      max-width: 100%;
      width: 100%;
    }
  }

  &.slide-content-centered {
    .swiper-slide {
      display: flex;
      align-items: center;
    }
  }

}

