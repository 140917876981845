@import 'css/shared';

.cart-offcanvas {

  .cart-packs {
    padding: 0 !important;
  }

  .footer-message {
    padding: map-get($spacers, 3) 0 0;
    text-align: center;
  }
  
  @include media-breakpoint-up(md) {}
  @include media-breakpoint-down(md) {}
 
}
