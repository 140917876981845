@import 'css/shared';

.Accordion {
  .row > [class^='col-'] {
    .accordion-item {
      border-bottom: 0;
    }
    &:last-child {
      .accordion-item {
        border-bottom: 1px solid var(--bs-light);
      }
    }  
  }

  .accordion-item {
    border-left: 0;
    border-right: 0;
  }
  
  .accordion-button:not(.collapsed){
    box-shadow: none !important;
  }

  @include media-breakpoint-down(lg) {}
  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-down(lg) {}
}
