@import '../css/shared';

.Slider-button {

  svg {
    fill: #000000;
  }

  &.swiper-button-prev {left: -10px;}
  &.swiper-button-next {right: -10px;}
  &.swiper-button-prev,
  &.swiper-button-next {
    margin: auto;
    transition: opacity 0.3s;

    &.swiper-button-large {
      width: 55px;
      height: 55px;
      top: 0;
      bottom: 0;    
    }

    &.swiper-button-small {
      position: inherit;
    }

    &:after {
      display: none;
    }
  }

  @include media-breakpoint-down(lg) {
    &.swiper-button-prev,
    &.swiper-button-next {
      display: none;
    }
  }

}