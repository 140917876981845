@import 'css/shared';

.product-tile {
 
  .product-tile-basic-info {

    .discount-percentage {
      position: absolute;
      top: 1rem;
      left: 1rem;
      background: $promo;
      color: $white;
      padding: 3px 10px 0;
    }

    .product-tile-image-container {
      padding: 1.5rem 0 3rem 0;
    }

    h6 {text-transform: uppercase;}
    
  }

  .product-variant {

    .d-flex {

      .m-0 {margin: 0 !important}

      label {
        cursor: pointer;
        background: $white;
        border: 1px solid transparent;
      }

      input[type="radio"]:checked + label {
        border-color: $primary;
      }

    }

  }

  @include media-breakpoint-up(md) {

    .product-tile-basic-info {

      .discount-percentage {
        top: 1.5rem;
        left: 1.5rem;
      }

    }

  }

  @include media-breakpoint-down(md) {}
  
}
