@import 'css/shared';

Footer {

  .NewsletterForm {
    a {color: $white}
  }
 
  @include media-breakpoint-up(lg) {
    .accordion-item {
      .accordion-button {
        font-size: 1rem !important;
        font-weight: 700;
        margin-bottom: 0.5rem;
      }
    }
    .row > [class^='col-'] {
      &:last-child {
        .accordion-item {
          border-bottom: 0 !important;
        }
      }  
    }
  
  }

  @include media-breakpoint-down(lg) {
    .Accordion {
      .accordion-item {
        border-color: $white;
      }
      .accordion-button::after {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      }
      .accordion-button:not(.collapsed)::after {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");  
      }
      button {
        color: $white !important;
      }
      
      a {        
        color: $white !important;
      }
    }
    .footerSocials {
      justify-content: center !important;
    }

    .footerPayments{
      padding: 1rem;
    }

    .footerNewsletter {
      background: $gray-100;
      color: $black;
    }

    .footerCopyright {
      padding: 1rem;
      color: $white;;
    }

  }
}