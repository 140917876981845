@import 'css/shared';

.recipe-product {
 
  .recipe-product-image-container {
    position: relative;
    padding-top: 1.5rem;

    .discount-percentage {
      position: absolute;
      top: 0;
      left: 0;
      padding: 3px 10px 0;
    }
    
  }

  .recipe-product-variant {
      label {
        cursor: pointer;
        border: 1px solid transparent;
      }

      input[type="radio"]:checked + label {
        border-color: $primary;
      }

    }
  
}
