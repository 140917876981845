@import 'css/shared';

.OurManufacturing {
    .tomato-box {
        @include media-breakpoint-up(xl) {
            margin-top: 6rem !important;
            margin-bottom: 4rem !important;
        }
    }
    .tomato-box-img {
        $top: 80%;
        transform: translate(-50%, -$top) !important;
        top: $top;
        left: 50%;
    }
}
