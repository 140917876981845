@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icons/fonts/icomoon.eot?27wbv4');
    src: url('../fonts/icons/fonts/icomoon.eot?27wbv4#iefix') format('embedded-opentype'),
      url('../fonts/icons/fonts/icomoon.ttf?27wbv4') format('truetype'),
      url('../fonts/icons/fonts/icomoon.woff?27wbv4') format('woff'),
      url('../fonts/icons/fonts/icomoon.svg?27wbv4#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
.gloria {
    font-family: 'Gloria Hallelujah', cursive;
}

.display-1, .display-2, .display-3, .display-4, .display-5, .display-6 {
    @extend .gloria;
    @extend .text-primary;
}

.extra-small {
    font-size: $font-size-base * 0.813;
  }
  