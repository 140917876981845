@import '../css/shared';

.form-group {
  .form-label {
    font-weight: bold;
    @extend .small;
    &.required:after {
      content: '*';
      margin-left: spacer(1);
      color: $danger;
    }
  }
}

.Pagination {
  .d-flex {
    @include media-breakpoint-up(md) {
      justify-content: end;
    }

    @include media-breakpoint-down(md) {
      justify-content: center;
    }
  }

  /*//-- Arrow Start --//*/

  .arrow-next,
  .arrow-prev,
  .arrow-to-first,
  .arrow-to-last {
    font-size: 0;
    display: flex;
    align-items: center;

    .page-link {
      min-width: 0 !important;

      &:before {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        background-size: 11px auto;
        //        background-color: var(--bs-secondary);
        background-repeat: no-repeat;
        background-attachment: scroll;
      }
    }
  }

  .arrow-prev {
    a {
      padding-left: 0 !important;
      &:before {
        background-image: url(../images/icons/SVG/small-arrow-left.svg);
        background-position: 6px 5px;
      }
    }
  }

  .arrow-next {
    a {
      padding-right: 0 !important;
      &:before {
        background-image: url(../images/icons/SVG/small-arrow-right.svg);
        background-position: 6px 5px;
      }
    }
  }

  /*//-- Page Item Start --//*/

  .page-item {
    .page-link {
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 2px solid transparent;
      color: var(--primary);
      background: transparent;
      display: inline-block;
      text-align: center;
      padding: 0 5px;
      box-shadow: none !important;
      @extend .small !optional;
      @extend .fw-bold !optional;

      @include media-breakpoint-up(md) {
        min-width: 20px;
      }
    }

    &.active {
      background: transparent;

      .page-link {
        border-color: var(--bs-primary);
      }
    }
  }
}

.offcanvas {
  .offcanvas-title {
    @extend .text-primary;
    @extend .display-2;
    @extend .text-initial;
  }
}

.MenuAccount {
  .list-group-item {
    color: $primary !important;
    &.active {
      color: $white !important;
    }
  }
}

.AccountHomePage {
  svg {
    @extend .text-primary;
  }
}

.BoxHomeAccount {
  .box-title {
    @extend .text-primary;
  }
}

.PageWithSubpageHeader {
  .title {
    @extend .display-2;
    @extend .text-initial;
  }
}
