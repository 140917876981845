@import '../css/shared';

.cart-page {

  .cart-summary {

    h6 {font-weight: 700;}

  }

  .missing-to-free-shipping {
    background: $white;
  }

  #form-cartGenericCoupon {
    input {
      border-color: var(--bs-dark) !important;
    }

    button {
      z-index: 100;
      opacity: 1 !important;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 1px;
        height: calc(100% - 14px);
        background: var(--bs-dark);
      }
    }
  }

}