@import 'functions';
@import 'bootstrap';
@import 'mixins';
@import 'fonts';
@import 'b2x-components';
@import 'b2x-theme/b2x-theme';

// Decommentare questo stile per debuggare i CSS.
// * {
//   background-color: rgba(green, 0.05);
// }

body {
  overflow-y: scroll;
}

.btn {
  text-transform: uppercase;
  &.no-uppercase,
  &.btn-link,
  &.btn-blank {
    text-transform: none;
  }
}

.btn-outline-primary {
  @include button-outline-variant($green, $black, $green-light, $green, $black);
  background: $white;
  &.disabled,
  &:disabled {
    background: $white;
  }
}

.text-initial {
  text-transform: initial !important;
}

.container-fluid-w-custom {
  @include media-breakpoint-up(xxl) {
    max-width: 1920px;
  }
}
