@import '~bootstrap/scss/functions';
@import 'variables';

// RIDEFINISCO ALCUNE COSE SENZA MODIFICARLE, GIUSTO PER AVERLE DIPONIBILI >>>>>

// BOOTSTRAP OVERRIDES BEFORE IMPORT >>>>>
$enable-negative-margins: true;

$custom-container-max-widths: (
  xxl: 1434px,
);

$font-family-sans-serif: 'Old Standard TT', sans-serif;

$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$h1-font-size: $font-size-base * 3.125;
$h2-font-size: $font-size-base * 1.875;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.125;

$headings-font-weight: 700;

$display-font-sizes: (
  1: 2.5rem,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $font-size-base,
);

$green: #094e45;
$green-light: #60a39a;
$red: #cf2e2e;
$violet: #774bb0;
$blue-light: #1aa2f0;
$white: #fff;
$yellow: #fffcd0;
$gray-100: #f6f6f6; // light
$gray-200: #d7d7d7;
$gray-300: #acacac;
$gray-400: #474747;
$black: #000;
$promo: $red;

$primary: $green;
$secondary: $yellow;
$lighter: $gray-200;
$warning: #ffc107;

$body-bg: $white;
$body-color: $black;

$custom-colors: (
  'promo': $red,
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'violet': $violet,
  'blu-light': $blue-light,
);

$body-color: $black;
$input-border-color: $gray-300;
$input-btn-line-height: 1.65;
$form-label-margin-bottom: 0;

$form-check-input-width: 1.125em;
$form-check-input-border: 1px solid $gray-300;
//$form-check-input-border-radius: 0;
$form-check-input-checked-color: $black;
$form-check-input-checked-bg-color: $white;
$form-check-input-checked-border-color: $gray-300;
$form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$form-check-input-checked-color}'/></svg>");
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg viewBox='-2 -2 14 14' xmlns='http://www.w3.org/2000/svg'><rect width='10' height='10' /></svg>");

$accordion-bg: transparent;
$accordion-button-focus-box-shadow: none;
$accordion-button-active-bg: $body-bg;
$accordion-button-active-color: $body-color;

$modal-header-border-width: 0;
$modal-footer-border-width: 0;

$enable-rounded: false;
$border-color: $gray-200;
$border-radius-sm: 5px;
$border-radius-lg: 1.5rem;

$offcanvas-horizontal-width: 500px;
$offcanvas-padding-x: 3rem;
$offcanvas-border-width: 0;

$breadcrumb-font-size: 14px;
$breadcrumb-divider-color: $black;
$breadcrumb-active-color: $black;
$breadcrumb-item-padding-x: 0.4rem;
$breadcrumb-divider: url("data:image/svg+xml,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 32 32'><path d='M24.41 14.6l-14.020-14.020c-0.77-0.775-2.030-0.775-2.8 0-0.775 0.775-0.775 2.030 0 2.8l12.615 12.615-12.615 12.615c-0.775 0.775-0.775 2.030 0 2.8 0.385 0.385 0.895 0.58 1.4 0.58s1.015-0.195 1.4-0.58l14.020-14.020c0.775-0.775 0.775-2.030 0-2.8z'></path></svg>");
$breadcrumb-margin-bottom: 0.5rem;

// BOOTSTRAP OVERRIDES BEFORE IMPORT <<<<<

@import '../../node_modules/bootstrap/scss/variables';
// @import '/node_modules/bootstrap/scss/utilities';

$container-max-widths: map-merge($container-max-widths, $custom-container-max-widths);
$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
$utilities-text: map-merge($utilities-text, $custom-colors);
$utilities-text-colors: map-loop($utilities-text, rgba-css-var, '$key', 'text');
$utilities-bg: map-merge($utilities-bg, $custom-colors);
$utilities-bg-colors: map-loop($utilities-bg, rgba-css-var, '$key', 'bg');

.zindex-dropdown {
  z-index: $zindex-dropdown;
}
.zindex-sticky {
  z-index: $zindex-sticky;
}
.zindex-fixed {
  z-index: $zindex-fixed;
}
.zindex-modal-backdrop {
  z-index: $zindex-modal-backdrop;
}
.zindex-modal {
  z-index: $zindex-modal;
}
.zindex-popover {
  z-index: $zindex-popover;
}
.zindex-tooltip {
  z-index: $zindex-tooltip;
}
