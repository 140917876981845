@import 'css/shared';

.DesktopHeaderSticky {
    &.sticky {
        .logo {
            width: 120px;
        }
    }
}
 
