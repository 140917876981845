@import '../css/shared';

.ProductPage {

  .wishlist-button-container {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 100;
  }

  .Accordion {
    .accordion-header {
      button.accordion-button {
        font-weight: 700;
        text-transform: uppercase;
        &:focus, &:active, &:not(.collapsed) {
          background: transparent;
          border-color: transparent;
          outline: 0;
          box-shadow: none;
          color: initial;
        }
      } 
    }

    table tbody tr:last-child th,
    table tbody tr:last-child td {
      border: 0;
    }
  } 

  .productVariant {

    .d-flex {

      .m-0 {margin: 0 !important}

      label {
        cursor: pointer;
        background: $white;
        border: 1px solid transparent;
      }

      input[type="radio"]:checked + label {
        border-color: $primary;
      }

    }

  }

  @include media-breakpoint-up(lg) {
  }

  @include media-breakpoint-down(md) {

  }

}