@import 'css/shared';

.best-price {

    del {
        color: $gray-300;
    }
    
}

.best-price-modal {

    .price-container {

        .crossed-out-price, 
        .price {
            font-size: $h3-font-size;
        }

        .price {font-weight: 700;}

    }

    del {
        color: $gray-300;
    }

    @include media-breakpoint-up(lg) {}
    @include media-breakpoint-down(lg) {}

}


.ProductPage .best-price button svg,
.ProductTile .best-price button svg {
    font-size: 30px !important;
    vertical-align: baseline;
}
