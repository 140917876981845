@import '../css/shared';

.SliderA {
  max-width: 1920px;
  margin: 0 auto;

  .message {
    padding-left: 12px;
    padding-right: 12px;
  }
  
  .slide {
    width: 100% !important;
    padding: 0 !important;
  }

  @include media-breakpoint-up(md) {

    .message {
      position: absolute;
      z-index: 100;
      bottom: 0;
      width: 100%;
    }

    .swiper-pagination {display: none;}

  }

  @include media-breakpoint-down(md) {
    .message {
      text-align: center !important;
    }
    
    .description, .title {
      color: $black !important;
    }
    .swiper-button-prev,
    .swiper-button-next {
      display: none;
    }

    .swiper {
      padding-bottom: 30px;
    }

    .swiper-pagination {
      top: calc(100% - 30px) !important;
      bottom: auto !important;
    }

  }

  .Video.video-background iframe, .Video.video-background video {
    width: 100% !important;
    height: auto !important;
  }

}